/** Connectors **/
export const FIRST = 0; // For use with first line????\
export const AND = 1;
export const OR = 2;
export const ANDNOT = 3;

/** Fields **/
export const ANY = 10;
export const TITLE = 11;
export const PERSON = 12;
export const REL_PLACES = 13;
export const REL_SUBJECTS = 14;
export const REL_TERMS = 15;
export const PUB_PLACE = 16;
export const PUBLISHER = 17;
export const IDS = 18;
export const CREATE_DATE = 19;
export const ENTRY_DATE = 20;
export const RESOURCE_TYPE = 21;
export const DESCRIPTIONS = 22;
export const ANYSPECIAL = 23;

/** Scope **/
export const CONTAINS = 50;
export const EXACTLY = 51;
export const STARTSWITH = 52;
export const ENDSWITH = 53;
export const BETWEEN = 54;
export const LAST1YEAR = 55;
export const LAST5YEARS = 56;
export const LAST10YEARS = 57;

export const corr = {
    [FIRST]: '',
    [AND]: 'and',
    [OR]: 'or',
    [ANDNOT]: 'and not',
    [ANY]: 'any field',
    [TITLE]: 'title/header/name',
    [PERSON]: 'agent',
    [REL_PLACES]: 'related places',
    [REL_SUBJECTS]: 'related subjects',
    [REL_TERMS]: 'related terms',
    [PUB_PLACE]: 'publication place',
    [PUBLISHER]: 'publisher',
    [IDS]: 'id',
    [CREATE_DATE]: 'creation date',
    [ENTRY_DATE]: 'upload date',
    [RESOURCE_TYPE]: 'resource type',
    [CONTAINS]: 'contains',
    [EXACTLY]: 'is exactly',
    [STARTSWITH]: 'starts with',
    [ENDSWITH]: 'ends with',
    [BETWEEN]: 'is between',
    [LAST1YEAR]: 'within the last year',
    [LAST5YEARS]: 'in the last 5 years',
    [LAST10YEARS]: 'in the last 10 years',
};

export const fieldLookup = {
    [ANY]: 'Any',
    [TITLE]: 'Title/Name/Term',
    [RESOURCE_TYPE]: 'Resource Type',
    [PERSON]: 'Creator/Author',
    [REL_PLACES]: 'Related Places',
    [REL_SUBJECTS]: 'Related Subjects',
    [REL_TERMS]: 'Related Terms',
    [PUB_PLACE]: 'Place of Publication',
    [PUBLISHER]: 'Publisher',
    [IDS]: 'ID Numbers',
    [CREATE_DATE]: 'Create Date',
    [ENTRY_DATE]: 'Upload Date',
};

export const scopeLookup = {
    [CONTAINS]: 'Contains',
    [EXACTLY]: 'Exactly',
    [STARTSWITH]: 'Starts With',
    [ENDSWITH]: 'Ends With',
    [LAST1YEAR]: 'Last year',
    [LAST5YEARS]: 'Last 5 years',
    [LAST10YEARS]: 'Last 10 years',
    [BETWEEN]: 'Between',
};

/** Solr fields to use for each field choice listed above, the number must match the constant value **/
export const SOLRFIELDS = {
    // ANY
    [ANY]: ['text'],
    [ANYSPECIAL]: ['titles', 'names', 'creators'],
    // TITLE
    [TITLE]: ['titles', 'names'],
    // PERSON
    [PERSON]: ['creators'],
    // RELATED KMAPS
    [REL_PLACES]: ['kmapid_places_idfacet'],
    [REL_SUBJECTS]: ['kmapid_subjects_idfacet'],
    [REL_TERMS]: ['kmapid_terms_idfacet'],
    // PUB FIELDS
    [PUB_PLACE]: ['pubplace_s'],
    [PUBLISHER]: ['publisher_s'],
    // ID FIELDS
    [IDS]: ['ids'],
    // DATE FIELDS
    [CREATE_DATE]: ['date_start'], // Note: for sources do not need 'pubyear_s' because date_start is the UTC version of it
    [ENTRY_DATE]: ['node_created'],
    [RESOURCE_TYPE]: ['asset_type'],
    // DESCRIPTIONS
    [DESCRIPTIONS]: ['descriptions'],
    // Foreign Names
    /*'name_tibt',
    'name_hans',
    'name_zh',
    'name_deva',*/
};

export const TIB_FIELDS = [
    'title',
    'caption',
    'name_tibt',
    'title_alt_t',
    'title_corpus_bo_t',
    'title_long_bo_t',
    'title_short_t',
    'caption_alt_txt',
];

export const ASSET_TYPES = {
    'audio-video': 'Audio-Visual',
    collections: 'Collections',
    images: 'Images',
    places: 'Places',
    sources: 'Sources',
    subjects: 'Subjects',
    // terms: 'Terms',
    'terms-bo': 'Terms, Tibetan',
    'terms-ne': 'Terms, Newar',
    texts: 'Texts',
    /* visuals: 'Visualizations', TODO: Add back in and filter according to project */
};

export const ASSET_SUBTYPES = {
    'audio-video': ['audio', 'video'],
    collections: ['audio-video', 'images', 'sources', 'texts'], // TODO: remove subtypes not in project: removing , 'visuals' for now
    visuals: ['chart', 'graph', 'timeline', 'map'], // TODO: need to fill out completely only partial right now.
};

export const REL_FIELDS = {
    [REL_PLACES]: 'places',
    [REL_SUBJECTS]: 'subjects',
    [REL_TERMS]: 'terms',
};

export function isDate(choice) {
    const choices = [CREATE_DATE, ENTRY_DATE];
    return choices.includes(choice * 1);
}

export function needsDateString(choice) {
    const choices = [EXACTLY, BETWEEN];
    return choices.includes(choice * 1);
}
