import React, { useState, useEffect } from 'react';
import { MdLogin, MdCheckCircle } from 'react-icons/all';
import { GetSessionID, GetUID } from './MandalaSession';
import useMandala from '../hooks/useMandala';
import { Button } from 'react-bootstrap';

/**
 * Displays a login link after checking the PING url to see if SOLR Proxy
 * still considers user logged in, regardless of whether logged into Mandala.
 * Runs with every page load in WP.
 *
 * @returns {JSX.Element|null}
 * @constructor
 */
export function LoginLink() {
    const [isLoggedIn, setLogin] = useState(false);

    useEffect(() => {
        const pingurl = process.env?.REACT_APP_PING_URL + '?';
        if (pingurl) {
            // The Ping URL tells whether the Solr Proxy considers user logged in. It timesout after a while
            async function checkLogin() {
                let full_url =
                    pingurl +
                    new URLSearchParams({
                        sid: sid,
                    });
                try {
                    const response = await fetch(full_url, {
                        method: 'GET',
                        headers: {
                            accept: 'application/json',
                        },
                    });
                    if (!response.ok) {
                        throw new Error('Data could not be fetched!');
                    } else {
                        return response.json();
                    }
                } catch (e) {
                    console.error(
                        'Cannot get response from ping in mandala react: ' +
                            full_url,
                        e
                    );
                }
            }
            checkLogin().then((res) => {
                setLogin(res?.loggedIn);
            });
        }
    }, []);

    /*
    useEffect(() => {
        console.log("Is logged in", isLoggedIn);
    }, [isLoggedIn]);
 */

    // If not login URL, don't show any icons at all
    if (
        !process.env?.REACT_APP_LOGIN_URL ||
        !process.env?.REACT_APP_LOGOUT_URL ||
        !process.env?.REACT_APP_HOME_URL
    ) {
        return null;
    }

    const sid = GetSessionID();
    const icon = isLoggedIn ? <LogoutIcon sid={sid} /> : <LoginIcon />;

    return <>{icon}</>;
}

/**
 * Icon to display for logging out when already logged in
 *
 * @returns {JSX.Element|null}
 * @constructor
 */
function LogoutIcon() {
    const data = {
        url_json:
            'https://mandala-dev.internal.lib.virginia.edu/general/api/user/current',
        asset_type: 'user',
        uid: 'current-user',
        id: 'current-user',
    };
    const {
        isLoading: isUserLoading,
        data: userinfo,
        isError: isUserError,
        error: userError,
    } = useMandala(data);

    const logout = function () {
        window.location.href =
            process.env.REACT_APP_LOGOUT_URL +
            '?returl=' +
            process.env.REACT_APP_HOME_URL;
    };
    if (isUserLoading) {
        return null;
    }
    if (isUserError || userinfo.uid === 0) {
        console.log('Not logged into mandala', userError, userinfo);
        logout();
    }
    const title = `Mandala User ${userinfo?.name} (${userinfo?.uid}) (Click to logout)`;
    return (
        <button
            className="mndl-access logout btn"
            title={title}
            onClick={logout}
        >
            <MdCheckCircle />
        </button>
    );
}

/**
 * Icon to display for logging in when not logged in
 *
 * @returns {JSX.Element}
 * @constructor
 */
function LoginIcon() {
    /*
    const login = function () {
        window.location.href =
            process.env.REACT_APP_LOGIN_URL +
            '?returl=' +
            process.env.REACT_APP_HOME_URL;
    };*/

    const login = function () {
        window.location.href =
            process.env.REACT_APP_LOGIN_URL +
            '?returl=' +
            process.env.REACT_APP_HOME_URL +
            '&logintype=saml';
    };

    return (
        <div className="mndl-access login m-3">
            <Button className="btn btn-primary" onClick={login}>
                Login <MdLogin />{' '}
            </Button>
        </div>
    );
}
