import {
    AND,
    ANY,
    CONTAINS,
    FIRST,
    isDate as checkForDate,
    RESOURCE_TYPE,
} from './SearchConstants';
import { v4 as uid } from 'uuid';

export class FormRowTemplate {
    constructor(
        n,
        conn = AND,
        field = ANY,
        scope = CONTAINS,
        value = '',
        subscope = false,
        value2 = '',
        type = 'normal'
    ) {
        this.uid = uid(); //  uid
        this.n = n;
        this.type = type;
        this.conn = n === 0 ? FIRST : conn;
        this.field = field;
        this.scope = scope;
        this.value = value;
        this.subscope = subscope;
        this.value2 = value2;
    }

    id() {
        if (this?.n && this?.uid) {
            return `query-row-${this.n}-${this.uid}`;
        } else {
            return uid();
        }
    }

    isAssetType() {
        return this.field === RESOURCE_TYPE;
    }

    isDate() {
        return checkForDate(this.field);
    }

    isFirst() {
        return this.conn === FIRST;
    }

    setn(nval) {
        this.n = nval;
        if (nval === 0) {
            this.conn = FIRST;
        }
    }
}
