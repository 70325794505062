// Taken from https://arshavidya.org.uk/vyasa.html (Nov 1, 2023) Modified by Than Grove
// https://arshavidya.org.uk/a/java/toDevanagari.js

import { EwtsConverter } from './src/EwtsConverter.mjs';
import { toDevanagari } from './src/DevanagariConverter';

const ewts = new EwtsConverter();
export function initConverters() {
    // TODO: Convert only the selected text, especially for Sanskrit
    document.addEventListener('keydown', (event) => {
        if (event.code === 'KeyT' && event.ctrlKey === true) {
            convertToTib(event.shiftKey);
        } else if (event.code === 'KeyW' && event.ctrlKey === true) {
            convertToWylie(event.shiftKey);
        } else if (event.code === 'KeyS' && event.ctrlKey === true) {
            convertToDeva();
        }
    });
}

function convertToTib(shift) {
    if (shift) {
        const selection = document.getSelection();
        const parent = selection.anchorNode.parentElement;
        const wyl = parent.innerText.replaceAll('/ ', '/_');
        const tib = ewts.to_unicode(wyl);
        if (tib) {
            parent.innerText = tib;
            parent.classList.add('bo');
        }
    } else {
        const selection = document.getSelection();
        const parent = selection.anchorNode.parentElement;
        let parentCnt = parent.innerHTML;
        const seltext = selection.focusNode.data.substring(
            selection.anchorOffset,
            selection.focusOffset
        );
        const tib = ewts.to_unicode(seltext.toLowerCase());
        parentCnt = parentCnt.replace(
            seltext,
            '<span class="bo">' + tib + '</span>'
        );
        parent.innerHTML = parentCnt;
    }
}

function convertToWylie(shift) {
    if (shift) {
        const selection = document.getSelection();
        const parent = selection.anchorNode.parentElement;
        const tib = parent.innerText;
        const wyl = ewts.to_ewts(tib);
        if (wyl) {
            parent.innerText = wyl;
            parent.classList.remove('bo');
        }
    } else {
        const selection = document.getSelection();
        const parent = selection.anchorNode.parentElement;
        let parentCnt = parent.innerHTML;
        const seltext = selection.focusNode.data.substring(
            selection.anchorOffset,
            selection.focusOffset
        );
        const wyl = ewts.to_ewts(seltext);
        // Try to replace span-wrapped version first. Second replace is backup if it is not wrapped.
        const wrappedwyl = '<span class="bo">' + wyl + '</span>';
        parentCnt = parentCnt.replace(seltext, wyl).replace(wrappedwyl, wyl);
        parent.innerHTML = parentCnt;
    }
}

function convertToDeva() {
    const selection = document.getSelection();
    const parent = selection.anchorNode.parentElement;
    let parentCnt = parent.innerHTML;
    const seltext = selection.focusNode.data.substring(
        selection.anchorOffset,
        selection.focusOffset
    );
    const san = toDevanagari(seltext.toLowerCase());
    parentCnt = parentCnt.replace(
        seltext,
        '<span class="sa">' + san + '</span>'
    );
    parent.innerHTML = parentCnt;
}
