export function toDevanagari(inp, transChoice = 'IAST') {
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////// Harvard-Kyoto ///////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    function HKtoIAST(x) {
        //vowels
        x = x.replace(/A/g, 'ā');
        x = x.replace(/I/g, 'ī');
        x = x.replace(/U/g, 'ū');
        x = x.replace(/lRR/g, 'ḹ');
        x = x.replace(/lR/g, 'ḷ');
        x = x.replace(/RR/g, 'ṝ');
        x = x.replace(/R/g, 'ṛ');

        //ansuvara and visarga
        x = x.replace(/M/g, 'ṃ');
        x = x.replace(/H/g, 'ḥ');

        //nasals
        x = x.replace(/G/g, 'ṅ');
        x = x.replace(/J/g, 'ñ');
        x = x.replace(/N/g, 'ṇ');

        //cerebral
        x = x.replace(/T/g, 'ṭ');
        x = x.replace(/D/g, 'ḍ');

        //sibilants
        x = x.replace(/z/g, 'ś');
        x = x.replace(/S/g, 'ṣ');

        return x;
    }

    if (transChoice == 'HK') {
        inp = HKtoIAST(inp);
        //inp = inp.replace(/ē/g, "e");
        //console.log(inp);
    }

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////// ISO 15919 ///////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    //IAST ISO

    //e	ē   \\u0065 \\u0113
    //o	ō   \\u006F  \\u014D
    //ṃ	ṁ   \\u1E43  \\u1E41
    //ṛ	r̥
    //ṝ	r̥̄
    //ḷ	l̥
    //ḹ	l̥̄
    //console.log(inp);

    function ISOtoIAST(x) {
        x = x.replace(/ē/g, 'e');
        x = x.replace(/ō/g, 'o');
        x = x.replace(/ṁ/g, 'ṃ');
        x = x.replace(/r̥̄/g, 'ṝ');
        x = x.replace(/r̥/g, 'ṛ');
        x = x.replace(/l̥̄/g, 'ḹ');
        x = x.replace(/l̥/g, 'ḷ');

        return x;
    }

    if (transChoice == 'ISO') {
        inp = ISOtoIAST(inp);
        //inp = inp.replace(/ē/g, "e");
        //console.log(inp);
    }

    /////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////    IAST   ///////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    //convert to lower case;
    inp = inp.toLowerCase();
    //convert to Unicode Hex
    inp = '\\u0020' + toUni(inp); //add space

    var rVow = [
        '\\u0061',
        '\\u0101',
        '\\u0069',
        '\\u012b',
        '\\u0075',
        '\\u016b',
        '\\u1e5b',
        '\\u1e5d',
        '\\u0065',
        '\\u0061\\u0069',
        '\\u006f',
        '\\u0061\\u0075',
        '\\u1e37',
        '\\u1e39',
        '\\u1e43',
        '\\u1e25',
    ];

    //-------------- Start Conversion ---------------//

    //Words starting with vowels
    //dipthongs
    inp = inp.replace(/\\u0020\\u0065/g, '\\u0020\\u090f'); //if 'e' preceeded by space
    inp = inp.replace(/\\u000a\\u0065/g, '\\u000a\\u090f'); //if 'e' preceeded by new line
    inp = inp.replace(/\\u0020\\u0061\\u0069/g, '\\u0020\\u0910'); //if 'ai' preceeded by space
    inp = inp.replace(/\\u000a\\u0061\\u0069/g, '\\u000a\\u0910'); //if 'ai' preceeded by new line
    inp = inp.replace(/\\u0020\\u006f/g, '\\u0020\\u0913'); //if 'o' preceeded by space
    inp = inp.replace(/\\u000a\\u006f/g, '\\u000a\\u0913'); //if '' preceeded by new line
    inp = inp.replace(/\\u0020\\u0061\\u0075/g, '\\u0020\\u0914'); //if 'au' preceeded by space
    inp = inp.replace(/\\u000a\\u0061\\u0075/g, '\\u000a\\u0914'); //if 'au' preceeded by space

    //Words starting with vowels
    //Simple vowels
    inp = inp.replace(/\\u0020\\u0061/g, '\\u0020\\u0905'); //if 'a' preceeded by space
    inp = inp.replace(/\\u000a\\u0061/g, '\\u000a\\u0905');
    inp = inp.replace(/\\u0020\\u0101/g, '\\u0020\\u0906'); //if 'aa' preceeded by space
    inp = inp.replace(/\\u000a\\u0101/g, '\\u000a\\u0906');
    inp = inp.replace(/\\u0020\\u0069/g, '\\u0020\\u0907'); //if 'i' preceeded by space
    inp = inp.replace(/\\u000a\\u0069/g, '\\u000a\\u0907');
    inp = inp.replace(/\\u0020\\u012b/g, '\\u0020\\u0908'); //if 'ī' preceeded by space
    inp = inp.replace(/\\u000a\\u012b/g, '\\u000a\\u0908');
    inp = inp.replace(/\\u0020\\u0075/g, '\\u0020\\u0909'); //if 'u' preceeded by space
    inp = inp.replace(/\\u000a\\u0075/g, '\\u000a\\u0909');
    inp = inp.replace(/\\u0020\\u016b/g, '\\u0020\\u090a'); //if 'ū' preceeded by space
    inp = inp.replace(/\\u000a\\u016b/g, '\\u000a\\u090a');
    inp = inp.replace(/\\u0020\\u1e5b/g, '\\u0020\\u090b'); //if 'ṛ' preceeded by space
    inp = inp.replace(/\\u000a\\u1e5b/g, '\\u000a\\u090b');
    inp = inp.replace(/\\u0020\\u1e5d/g, '\\u0020\\u0960'); //if 'ṝ' preceeded by space
    inp = inp.replace(/\\u000a\\u1e5d/g, '\\u000a\\u0960');
    inp = inp.replace(/\\u0020\\u1e37/g, '\\u0020\\u090c'); //if 'ḷ' preceeded by space
    inp = inp.replace(/\\u000a\\u1e37/g, '\\u000a\\u090c');
    inp = inp.replace(/\\u0020\\u1e39/g, '\\u0020\\u0961'); //if 'ḹ' preceeded by space
    inp = inp.replace(/\\u000a\\u1e39/g, '\\u000a\\u0961');

    // function to replace symbol with replacement if following letter in class
    function followed(param, symbol, replacement) {
        var i;
        /*
        var find = 'abc';
        var re = new RegExp(find, 'g');

        str = str.replace(re, '');
        */

        for (i = 0; i < rVow.length; i++) {
            param = param.split(symbol + rVow[i]).join(replacement + rVow[i]);
        }

        return param;
    }

    //Non-final consonants
    //If subsequent letter in rVow then replace

    inp = followed(inp, '\\u006b\\u0068', '\\u0916'); //kh
    inp = followed(inp, '\\u006b', '\\u0915'); //k
    inp = followed(inp, '\\u0067\\u0068', '\\u0918'); //gh
    inp = followed(inp, '\\u0067', '\\u0917'); //g
    inp = followed(inp, '\\u1e45', '\\u0919'); //gutteral nasal

    inp = followed(inp, '\\u0063\\u0068', '\\u091b'); //ch
    inp = followed(inp, '\\u0063', '\\u091a'); //c
    inp = followed(inp, '\\u006a\\u0068', '\\u091d'); //jh
    inp = followed(inp, '\\u006a', '\\u091c'); //j
    inp = followed(inp, '\\u00f1', '\\u091e'); //ñ

    inp = followed(inp, '\\u1e6d\\u0068', '\\u0920'); //ṭh
    inp = followed(inp, '\\u1e6d', '\\u091f'); //ṭ
    inp = followed(inp, '\\u1e0d\\u0068', '\\u0922'); //ḍh
    inp = followed(inp, '\\u1e0d', '\\u0921'); //ḍ
    inp = followed(inp, '\\u1e47', '\\u0923'); //ṇ

    inp = followed(inp, '\\u0074\\u0068', '\\u0925'); //th
    inp = followed(inp, '\\u0074', '\\u0924'); //t
    inp = followed(inp, '\\u0064\\u0068', '\\u0927'); //dh
    inp = followed(inp, '\\u0064', '\\u0926'); //d
    inp = followed(inp, '\\u006e', '\\u0928'); //n

    inp = followed(inp, '\\u0070\\u0068', '\\u092b'); //ph
    inp = followed(inp, '\\u0070', '\\u092a'); //p
    inp = followed(inp, '\\u0062\\u0068', '\\u092d'); //bh
    inp = followed(inp, '\\u0062', '\\u092c'); //b
    inp = followed(inp, '\\u006d', '\\u092e'); //m

    inp = followed(inp, '\\u0079', '\\u092f'); //y
    inp = followed(inp, '\\u0072', '\\u0930'); //r
    inp = followed(inp, '\\u006c', '\\u0932'); //l
    inp = followed(inp, '\\u0076', '\\u0935'); //v
    inp = followed(inp, '\\u015b', '\\u0936'); //ś
    inp = followed(inp, '\\u1e63', '\\u0937'); //ṣ
    inp = followed(inp, '\\u0073', '\\u0938'); //s
    inp = followed(inp, '\\u0068', '\\u0939'); //h

    //Medial Vowels

    //dipthongs first
    inp = inp.replace(/\\u0065/g, '\\u0947'); //e
    inp = inp.replace(/\\u0061\\u0069/g, '\\u0948'); //ai
    inp = inp.replace(/\\u006f/g, '\\u094b'); //o
    inp = inp.replace(/\\u0061\\u0075/g, '\\u094c'); //au

    inp = inp.replace(/\\u0069/g, '\\u093f'); //i
    //simple vowels

    inp = inp.replace(/\\u0101/g, '\\u093e'); //ā
    inp = inp.replace(/\\u012b/g, '\\u0940'); //ī
    inp = inp.replace(/\\u0075/g, '\\u0941'); //u
    inp = inp.replace(/\\u016b/g, '\\u0942'); //ū
    inp = inp.replace(/\\u1e5b/g, '\\u0943'); //ṛ
    inp = inp.replace(/\\u1e5d/g, '\\u0944'); //ṝ

    inp = inp.replace(/\\u1e37/g, '\\u0962'); //ḷ
    inp = inp.replace(/\\u1e39/g, '\\u0963'); //ḹ
    inp = inp.replace(/\\u1e43/g, '\\u0902'); //ṃ
    inp = inp.replace(/\\u1e41/g, '\\u0902'); //ṃ (dot above)
    inp = inp.replace(/\\u1e25/g, '\\u0903'); //ḥ

    //final consonants
    inp = inp.replace(/\\u006b\\u0068/g, '\\u0916\\u094d'); //kh
    inp = inp.replace(/\\u006b/g, '\\u0915\\u094d'); //k
    inp = inp.replace(/\\u0067\\u0068/g, '\\u0918\\u094d'); //gh
    inp = inp.replace(/\\u0067/g, '\\u0917\\u094d'); //g
    inp = inp.replace(/\\u1e45/g, '\\u0919\\u094d'); //ṅ

    inp = inp.replace(/\\u0063\\u0068/g, '\\u091b\\u094d'); //ch
    inp = inp.replace(/\\u0063/g, '\\u091a\\u094d'); //c
    inp = inp.replace(/\\u006a\\u0068/g, '\\u091d\\u094d'); //jh
    inp = inp.replace(/\\u006a/g, '\\u091c\\u094d'); //j
    inp = inp.replace(/\\u00f1/g, '\\u091e\\u094d'); //ñ

    inp = inp.replace(/\\u1e6d\\u0068/g, '\\u0920\\u094d'); //ṭh
    inp = inp.replace(/\\u1e6d/g, '\\u091f\\u094d'); //ṭ
    inp = inp.replace(/\\u1e0d\\u0068/g, '\\u0922\\u094d'); //ḍh
    inp = inp.replace(/\\u1e0d/g, '\\u0921\\u094d'); //ḍ
    inp = inp.replace(/\\u1e47/g, '\\u0923\\u094d'); //ṇ

    inp = inp.replace(/\\u0074\\u0068/g, '\\u0925\\u094d'); //th
    inp = inp.replace(/\\u0074/g, '\\u0924\\u094d'); //t
    inp = inp.replace(/\\u0064\\u0068/g, '\\u0927\\u094d'); //dh
    inp = inp.replace(/\\u0064/g, '\\u0926\\u094d'); //d
    inp = inp.replace(/\\u006e/g, '\\u0928\\u094d'); //n

    inp = inp.replace(/\\u0070\\u0068/g, '\\u092b\\u094d'); //ph
    inp = inp.replace(/\\u0070/g, '\\u092a\\u094d'); //p
    inp = inp.replace(/\\u0062\\u0068/g, '\\u092d\\u094d'); //bh
    inp = inp.replace(/\\u0062/g, '\\u092c\\u094d'); //b
    inp = inp.replace(/\\u006d/g, '\\u092e\\u094d'); //m

    inp = inp.replace(/\\u0079/g, '\\u092f\\u094d'); //y
    inp = inp.replace(/\\u0072/g, '\\u0930\\u094d'); //r
    inp = inp.replace(/\\u006c/g, '\\u0932\\u094d'); //l
    inp = inp.replace(/\\u0076/g, '\\u0935\\u094d'); //v

    inp = inp.replace(/\\u015b/g, '\\u0936\\u094d'); //ś
    inp = inp.replace(/\\u1e63/g, '\\u0937\\u094d'); //ṣ
    inp = inp.replace(/\\u0073/g, '\\u0938\\u094d'); //s
    inp = inp.replace(/\\u0068/g, '\\u0939\\u094d'); //h

    //stri_unescape_unicode(inp)

    //Remove 'a'
    inp = inp.replace(/\\u0061/g, ''); //

    //Punctuation
    inp = inp.replace(/\\u002c/g, '\u0964'); //,  previously inp = inp.replace(/\\u002c/g, "\\u0020\\u0964");
    inp = inp.replace(/\\u002e/g, '\u0965'); //.  previously inp = inp.replace(/\\u002e/g, "\\u0020\\u0965");

    //Svaras
    inp = inp.replace(/\\u030e/g, '\\u1cda'); //dīrga svarita
    inp = inp.replace(/\\u030d/g, '\\u0951'); //Svarita
    inp = inp.replace(/\\u0331/g, '\\u0952'); //anudātta

    //swap order of svara and visarga/anusvara
    //svarita
    inp = inp.replace(/\\u0951\\u0902/g, '\\u0902\\u0951'); //anusvara
    inp = inp.replace(/\\u0951\\u0903/g, '\\u0903\\u0951'); //visarga
    //anudatta
    inp = inp.replace(/\\u0952\\u0902/g, '\\u0902\\u0952'); //anusvara
    inp = inp.replace(/\\u0952\\u0903/g, '\\u0903\\u0952'); //visarga
    //dirga svarita
    inp = inp.replace(/\\u1cda\\u0902/g, '\\u0902\\u1cda'); //anusvara
    inp = inp.replace(/\\u1cda\\u0903/g, '\\u0903\\u1cda'); //visarga

    //candrabindu
    inp = inp.replace(/\\uf141\\uf141/g, '\\u0901'); //candrabindu on top/in middle of word
    inp = inp.replace(/\\uf141/g, '\\ua8f3'); //candrabindu at end of word

    //avagraha
    inp = inp.replace(/\\u02bc/g, '\\u093d'); //styled apostrophe
    inp = inp.replace(/\\u2019/g, '\\u093d'); //styled apostrophe
    inp = inp.replace(/\\u0027/g, '\\u093d'); //unstyled apostrophe

    //Numerals
    inp = inp.replace(/\\u0030/g, '\\u0966');
    inp = inp.replace(/\\u0031/g, '\\u0967');
    inp = inp.replace(/\\u0032/g, '\\u0968');
    inp = inp.replace(/\\u0033/g, '\\u0969');
    inp = inp.replace(/\\u0034/g, '\\u096a');
    inp = inp.replace(/\\u0035/g, '\\u096b');
    inp = inp.replace(/\\u0036/g, '\\u096c');
    inp = inp.replace(/\\u0037/g, '\\u096d');
    inp = inp.replace(/\\u0038/g, '\\u096e');
    inp = inp.replace(/\\u0039/g, '\\u096f');

    //print output
    return fromUni(inp);
}

//note to self: replace all capital letters with lower case;

//console.log(toUni("abc"));
//console.log(fromUni("\\u0020\\u0061"));

function toDevanagariUpdate() {
    'use strict';
    /*jslint plusplus: true */

    //get input
    var inp = document.getElementById('romin').value;

    inp = inp + ' ';
    //output
    document.getElementById('devout').innerHTML = fromUni(inp);
}

export function toRoman(inp, transChoice = 'IAST') {
    var dVow = [
        '\\u0947',
        '\\u0948',
        '\\u094b',
        '\\u094c',
        '\\u093e',
        '\\u093f',
        '\\u0940',
        '\\u0941',
        '\\u0942',
        '\\u0943',
        '\\u0944',
        '\\u0962',
        '\\u0963',
    ];

    //convert to Unicode Hex
    inp = '\\u0020' + toUni(inp); //add space

    //Words starting with vowels
    //dipthongs
    inp = inp.replace(/\\u0020\\u090f/g, '\\u0020\\u0065'); //if 'e' preceeded by space

    inp = inp.replace(/\\u000a\\u090f/g, '\\u000a\\u0065'); //if 'e' preceeded by new line
    inp = inp.replace(/\\u0020\\u0910/g, '\\u0020\\u0061\\u0069'); //if 'ai' preceeded by space
    inp = inp.replace(/\\u000a\\u0910/g, '\\u000a\\u0061\\u0069'); //if 'ai' preceeded by new line
    inp = inp.replace(/\\u0020\\u0913/g, '\\u0020\\u006f'); //if 'o' preceeded by space
    inp = inp.replace(/\\u000a\\u0913/g, '\\u000a\\u006f'); //if '' preceeded by new line
    inp = inp.replace(/\\u0020\\u0914/g, '\\u0020\\u0061\\u0075'); //if 'au' preceeded by space
    inp = inp.replace(/\\u000a\\u0914/g, '\\u000a\\u0061\\u0075'); //if 'au' preceeded by space

    //Words starting with vowels
    //Simple vowels
    inp = inp.replace(/\\u0020\\u0905/g, '\\u0020\\u0061'); //if 'a' preceeded by space
    inp = inp.replace(/\\u000a\\u0905/g, '\\u000a\\u0061');
    inp = inp.replace(/\\u0020\\u0906/g, '\\u0020\\u0101'); //if 'aa' preceeded by space
    inp = inp.replace(/\\u000a\\u0906/g, '\\u000a\\u0101');
    inp = inp.replace(/\\u0020\\u0907/g, '\\u0020\\u0069'); //if 'i' preceeded by space
    inp = inp.replace(/\\u000a\\u0907/g, '\\u000a\\u0069');
    inp = inp.replace(/\\u0020\\u0908/g, '\\u0020\\u012b'); //if 'ī' preceeded by space
    inp = inp.replace(/\\u000a\\u0908/g, '\\u000a\\u012b');
    inp = inp.replace(/\\u0020\\u0909/g, '\\u0020\\u0075'); //if 'u' preceeded by space
    inp = inp.replace(/\\u000a\\u0909/g, '\\u000a\\u0075');
    inp = inp.replace(/\\u0020\\u090a/g, '\\u0020\\u016b'); //if 'ū' preceeded by space
    inp = inp.replace(/\\u000a\\u090a/g, '\\u000a\\u016b');
    inp = inp.replace(/\\u0020\\u090b/g, '\\u0020\\u1e5b'); //if 'ṛ' preceeded by space
    inp = inp.replace(/\\u000a\\u090b/g, '\\u000a\\u1e5b');
    inp = inp.replace(/\\u0020\\u0960/g, '\\u0020\\u1e5d'); //if 'ṝ' preceeded by space
    inp = inp.replace(/\\u000a\\u0960/g, '\\u000a\\u1e5d');
    inp = inp.replace(/\\u0020\\u090c/g, '\\u0020\\u1e37'); //if 'ḷ' preceeded by space
    inp = inp.replace(/\\u000a\\u090c/g, '\\u000a\\u1e37');
    inp = inp.replace(/\\u0020\\u0961/g, '\\u0020\\u1e39'); //if 'ḹ' preceeded by space
    inp = inp.replace(/\\u000a\\u0961/g, '\\u000a\\u1e39');

    //final consonants
    inp = inp.replace(/\\u0915\\u094d/g, '\\u006b'); //k
    inp = inp.replace(/\\u0916\\u094d/g, '\\u006b\\u0068'); //kh
    inp = inp.replace(/\\u0917\\u094d/g, '\\u0067'); //g
    inp = inp.replace(/\\u0918\\u094d/g, '\\u0067\\u0068'); //gh
    inp = inp.replace(/\\u0919\\u094d/g, '\\u1e45'); //ṅ

    inp = inp.replace(/\\u091a\\u094d/g, '\\u0063'); //c
    inp = inp.replace(/\\u091b\\u094d/g, '\\u0063\\u0068'); //ch
    inp = inp.replace(/\\u091c\\u094d/g, '\\u006a'); //j
    inp = inp.replace(/\\u091d\\u094d/g, '\\u006a\\u0068'); //jh
    inp = inp.replace(/\\u091e\\u094d/g, '\\u00f1'); //ñ

    inp = inp.replace(/\\u091f\\u094d/g, '\\u1e6d'); //ṭ
    inp = inp.replace(/\\u0920\\u094d/g, '\\u1e6d\\u0068'); //ṭh
    inp = inp.replace(/\\u0921\\u094d/g, '\\u1e0d'); //ḍ
    inp = inp.replace(/\\u0922\\u094d/g, '\\u1e0d\\u0068'); //ḍh
    inp = inp.replace(/\\u0923\\u094d/g, '\\u1e47'); //ṇ

    inp = inp.replace(/\\u0924\\u094d/g, '\\u0074'); //t
    inp = inp.replace(/\\u0925\\u094d/g, '\\u0074\\u0068'); //th
    inp = inp.replace(/\\u0926\\u094d/g, '\\u0064'); //d
    inp = inp.replace(/\\u0927\\u094d/g, '\\u0064\\u0068'); //dh
    inp = inp.replace(/\\u0928\\u094d/g, '\\u006e'); //n

    inp = inp.replace(/\\u092a\\u094d/g, '\\u0070'); //p
    inp = inp.replace(/\\u092b\\u094d/g, '\\u0070\\u0068'); //ph
    inp = inp.replace(/\\u092c\\u094d/g, '\\u0062'); //b
    inp = inp.replace(/\\u092d\\u094d/g, '\\u0062\\u0068'); //bh
    inp = inp.replace(/\\u092e\\u094d/g, '\\u006d'); //m

    inp = inp.replace(/\\u092f\\u094d/g, '\\u0079'); //y
    inp = inp.replace(/\\u0930\\u094d/g, '\\u0072'); //r
    inp = inp.replace(/\\u0932\\u094d/g, '\\u006c'); //l
    /*inp = inp.replace(/\\u0933\\u094d/g, "\\u006c"); //l ळ*/
    inp = inp.replace(/\\u0935\\u094d/g, '\\u0076'); //v

    inp = inp.replace(/\\u0936\\u094d/g, '\\u015b'); //ś
    inp = inp.replace(/\\u0937\\u094d/g, '\\u1e63'); //ṣ
    inp = inp.replace(/\\u0938\\u094d/g, '\\u0073'); //s
    inp = inp.replace(/\\u0939\\u094d/g, '\\u0068'); //h

    //Non-final consonants

    //if a consonant is not followed by roman ā, i, ī, u ū ṛ ṛ ḷ ḹ e ai o au
    //then convert consonant into transliteration and add roman a

    function addA(param, symbol, replacement) {
        var split = param.match(/.{1,6}/g);
        var splitTrueFalse = [];
        var i;

        //does split contain symbol?
        for (i = 0; i < split.length; i++) {
            splitTrueFalse[i] = Boolean(split[i] === symbol);
        }

        //get positions of symbol in split

        var whichMatch = [];
        splitTrueFalse.forEach(function (val, index) {
            if (val) {
                whichMatch.push(index);
            }
        });
        //                        var whichMatch = split.reduce((out, bool, index) => bool ? out.concat(index) : out, [] )
        //var whichMatch = [];
        //for (i = 0; i < splitTrueFalse.length; i++) {
        //    if (splitTrueFalse[i]) { splitTrueFalse.push(i); }
        //}

        //replace symbol with replacement + letter a if following letter not in dVow
        for (i = 0; i < whichMatch.length; i++) {
            var j = whichMatch[i] + 1; //get following position

            var test = !dVow.includes(split[j]); //following letter not in dVow

            if (test) {
                split[j - 1] = split[j - 1].replace(
                    split[j - 1],
                    replacement + '\\u0061'
                ); //then replace and add a
            }
        }

        //concatenate split
        param = split.join('');

        return param;
    }

    //if a consonant is  followed by roman ā, i, ī, u ū ṛ ṛ ḷ ḹ e ai o au ṃ ḥ
    //then replace with roman equivalent
    function noAddA(param, symbol, replacement) {
        var split = param.match(/.{1,6}/g);
        var splitTrueFalse = [];
        var i;

        //does split contain symbol?
        for (i = 0; i < split.length; i++) {
            splitTrueFalse[i] = Boolean(split[i] === symbol);
        }

        //get positions of symbol in split
        //var whichMatch = [];
        //for (i = 0; i < splitTrueFalse.length; i++) {
        //    if (splitTrueFalse[i]) { splitTrueFalse.push(i); }
        //}

        var whichMatch = [];
        splitTrueFalse.forEach(function (val, index) {
            if (val) {
                whichMatch.push(index);
            }
        });

        //var whichMatch = splitTrueFalse.reduce( (out, bool, index) => bool ? out.concat(index) : out, []);

        //replace symbol with replacement + letter a if following letter not in dVow
        for (i = 0; i < whichMatch.length; i++) {
            var j = whichMatch[i] + 1; //get following position

            //remaining letters
            var test = dVow.includes(split[j]); //is following letter in dVow?

            if (test) {
                split[j - 1] = split[j - 1].replace(split[j - 1], replacement); //then replace
            }
        }

        //concatenate split
        param = split.join('');

        return param;
    }

    inp = addA(inp, '\\u0916', '\\u006b\\u0068'); //kh
    inp = addA(inp, '\\u0915', '\\u006b'); //k
    inp = addA(inp, '\\u0918', '\\u0067\\u0068'); //gh
    inp = addA(inp, '\\u0917', '\\u0067'); //g
    inp = addA(inp, '\\u0919', '\\u1e45'); //gutteral nasal

    inp = addA(inp, '\\u091b', '\\u0063\\u0068'); //ch
    inp = addA(inp, '\\u091a', '\\u0063'); //c
    inp = addA(inp, '\\u091d', '\\u006a\\u0068'); //jh
    inp = addA(inp, '\\u091c', '\\u006a'); //j
    inp = addA(inp, '\\u091e', '\\u00f1'); //ñ

    inp = addA(inp, '\\u0920', '\\u1e6d\\u0068'); //ṭh
    inp = addA(inp, '\\u091f', '\\u1e6d'); //ṭ
    inp = addA(inp, '\\u0922', '\\u1e0d\\u0068'); //ḍh
    inp = addA(inp, '\\u0921', '\\u1e0d'); //ḍ
    inp = addA(inp, '\\u0923', '\\u1e47'); //ṇ

    inp = addA(inp, '\\u0925', '\\u0074\\u0068'); //th
    inp = addA(inp, '\\u0924', '\\u0074'); //t
    inp = addA(inp, '\\u0927', '\\u0064\\u0068'); //dh
    inp = addA(inp, '\\u0926', '\\u0064'); //d
    inp = addA(inp, '\\u0928', '\\u006e'); //n

    inp = addA(inp, '\\u092b', '\\u0070\\u0068'); //ph
    inp = addA(inp, '\\u092a', '\\u0070'); //p
    inp = addA(inp, '\\u092d', '\\u0062\\u0068'); //bh
    inp = addA(inp, '\\u092c', '\\u0062'); //b
    inp = addA(inp, '\\u092e', '\\u006d'); //m

    inp = addA(inp, '\\u092f', '\\u0079'); //y
    inp = addA(inp, '\\u0930', '\\u0072'); //r
    inp = addA(inp, '\\u0932', '\\u006c'); //l

    inp = addA(inp, '\\u0935', '\\u0076'); //v
    inp = addA(inp, '\\u0936', '\\u015b'); //ś
    inp = addA(inp, '\\u0937', '\\u1e63'); //ṣ
    inp = addA(inp, '\\u0938', '\\u0073'); //s
    inp = addA(inp, '\\u0939', '\\u0068'); //h

    //if consonant followed by letter in dVow, replace with roman version
    inp = noAddA(inp, '\\u0916', '\\u006b\\u0068'); //kh
    inp = noAddA(inp, '\\u0915', '\\u006b'); //k
    inp = noAddA(inp, '\\u0918', '\\u0067\\u0068'); //gh
    inp = noAddA(inp, '\\u0917', '\\u0067'); //g
    inp = noAddA(inp, '\\u0919', '\\u1e45'); //gutteral nasal

    inp = noAddA(inp, '\\u091b', '\\u0063\\u0068'); //ch
    inp = noAddA(inp, '\\u091a', '\\u0063'); //c
    inp = noAddA(inp, '\\u091d', '\\u006a\\u0068'); //jh
    inp = noAddA(inp, '\\u091c', '\\u006a'); //j
    inp = noAddA(inp, '\\u091e', '\\u00f1'); //ñ

    inp = noAddA(inp, '\\u0920', '\\u1e6d\\u0068'); //ṭh
    inp = noAddA(inp, '\\u091f', '\\u1e6d'); //ṭ
    inp = noAddA(inp, '\\u0922', '\\u1e0d\\u0068'); //ḍh
    inp = noAddA(inp, '\\u0921', '\\u1e0d'); //ḍ
    inp = noAddA(inp, '\\u0923', '\\u1e47'); //ṇ

    inp = noAddA(inp, '\\u0925', '\\u0074\\u0068'); //th
    inp = noAddA(inp, '\\u0924', '\\u0074'); //t
    inp = noAddA(inp, '\\u0927', '\\u0064\\u0068'); //dh
    inp = noAddA(inp, '\\u0926', '\\u0064'); //d
    inp = noAddA(inp, '\\u0928', '\\u006e'); //n

    inp = noAddA(inp, '\\u092b', '\\u0070\\u0068'); //ph
    inp = noAddA(inp, '\\u092a', '\\u0070'); //p
    inp = noAddA(inp, '\\u092d', '\\u0062\\u0068'); //bh
    inp = noAddA(inp, '\\u092c', '\\u0062'); //b
    inp = noAddA(inp, '\\u092e', '\\u006d'); //m

    inp = noAddA(inp, '\\u092f', '\\u0079'); //y
    inp = noAddA(inp, '\\u0930', '\\u0072'); //r
    inp = noAddA(inp, '\\u0932', '\\u006c'); //l

    inp = noAddA(inp, '\\u0935', '\\u0076'); //v
    inp = noAddA(inp, '\\u0936', '\\u015b'); //ś
    inp = noAddA(inp, '\\u0937', '\\u1e63'); //ṣ
    inp = noAddA(inp, '\\u0938', '\\u0073'); //s
    inp = noAddA(inp, '\\u0939', '\\u0068'); //h

    //Medial Vowels

    //dipthongs first

    inp = inp.replace(/\\u0947/g, '\\u0065'); //e
    inp = inp.replace(/\\u0948/g, '\\u0061\\u0069'); //ai
    inp = inp.replace(/\\u094b/g, '\\u006f'); //o
    inp = inp.replace(/\\u094c/g, '\\u0061\\u0075'); //au

    //simple vowels
    inp = inp.replace(/\\u093e/g, '\\u0101'); //ā
    inp = inp.replace(/\\u093f/g, '\\u0069'); //i
    inp = inp.replace(/\\u0940/g, '\\u012b'); //ī
    inp = inp.replace(/\\u0941/g, '\\u0075'); //u
    inp = inp.replace(/\\u0942/g, '\\u016b'); //ū
    inp = inp.replace(/\\u0943/g, '\\u1e5b'); //ṛ
    inp = inp.replace(/\\u0944/g, '\\u1e5d'); //ṝ

    inp = inp.replace(/\\u0962/g, '\\u1e37'); //ḷ
    inp = inp.replace(/\\u0963/g, '\\u1e39'); //ḹ
    inp = inp.replace(/\\u0902/g, '\\u1e43'); //ṃ
    inp = inp.replace(/\\u0903/g, '\\u1e25'); //ḥ

    //Punctuation
    inp = inp.replace(/\\u0020\\u0964/g, '\\u002c'); //,
    inp = inp.replace(/\\u0020\\u0965/g, '\\u002e'); //.

    inp = inp.replace(/\\u0964/g, '\\u002c'); //,
    inp = inp.replace(/\\u0965/g, '\\u002e'); //.

    //Svaras
    inp = inp.replace(/\\u1cda/g, '\\u030e'); //dīrga svarita
    inp = inp.replace(/\\u0951/g, '\\u030d'); //Svarita
    inp = inp.replace(/\\u0952/g, '\\u0331'); //anudātta

    //swap order of svara and visarga/anusvara
    //svarita
    inp = inp.replace(/\\u0902\\u0951/g, '\\u0951\\u0902'); //anusvara
    inp = inp.replace(/\\u0903\\u0951/g, '\\u0951\\u0903'); //visarga
    //anudatta
    inp = inp.replace(/\\u0902\\u0952/g, '\\u0952\\u0902'); //anusvara
    inp = inp.replace(/\\u0903\\u0952/g, '\\u0952\\u0903'); //visarga
    //dirga svarita
    inp = inp.replace(/\\u0902\\u1cda/g, '\\u1cda\\u0902'); //anusvara
    inp = inp.replace(/\\u0903\\u1cda/g, '\\u1cda\\u0903'); //visarga

    //candrabindu
    inp = inp.replace(/\\u0901/g, '\\uf141'); //candrabindu on top/in middle of word
    inp = inp.replace(/\\ua8f3/g, '\\uf141'); //candrabindu at end of word

    //avagraha
    inp = inp.replace(/\\u093d/g, '\\u02bc'); //styled apostrophe

    //Numerals
    inp = inp.replace(/\\u0966/g, '\\u0030');
    inp = inp.replace(/\\u0967/g, '\\u0031');
    inp = inp.replace(/\\u0968/g, '\\u0032');
    inp = inp.replace(/\\u0969/g, '\\u0033');
    inp = inp.replace(/\\u096a/g, '\\u0034');
    inp = inp.replace(/\\u096b/g, '\\u0035');
    inp = inp.replace(/\\u096c/g, '\\u0036');
    inp = inp.replace(/\\u096d/g, '\\u0037');
    inp = inp.replace(/\\u096e/g, '\\u0038');
    inp = inp.replace(/\\u096f/g, '\\u0039');

    /*//If user specifies; convert to sentence case;
    if(sentenceCase = true){
    var rg = /(^\w{1}|\.\s*\w{1})/gi;
    inp = inp.replace(rg, function(toReplace) {
            return toReplace.toUpperCase();
    });
    }*/

    //convert out of unicode
    inp = fromUni(inp);

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////// Harvard-Kyoto ///////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    function IASTtoHK(x) {
        //vowels
        x = x.replace(/ā/g, 'A');
        x = x.replace(/ī/g, 'I');
        x = x.replace(/ū/g, 'U');
        x = x.replace(/ḹ/g, 'lRR');
        x = x.replace(/ḷ/g, 'lR');
        x = x.replace(/ṝ/g, 'RR');
        x = x.replace(/ṛ/g, 'R');

        //ansuvara and visarga
        x = x.replace(/ṃ/g, 'M');
        x = x.replace(/ḥ/g, 'H');

        //nasals
        x = x.replace(/ṅ/g, 'G');
        x = x.replace(/ñ/g, 'J');
        x = x.replace(/ṇ/g, 'N');

        //cerebral
        x = x.replace(/ṭ/g, 'T');
        x = x.replace(/ḍ/g, 'D');

        //sibilants
        x = x.replace(/ś/g, 'z');
        x = x.replace(/ṣ/g, 'S');

        return x;
    }

    if (transChoice == 'HK') {
        inp = IASTtoHK(inp);
        //console.log(inp);
    }

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////// ISO 15919 ///////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    function IASTtoISO(x) {
        x = x.replace(/e/g, 'ē');
        x = x.replace(/o/g, 'ō');
        x = x.replace(/ṃ/g, 'ṁ'); //ṃ
        x = x.replace(/ṝ/g, 'r̥̄'); //ṝ
        x = x.replace(/ṛ/g, 'r̥'); //ṛ
        x = x.replace(/ḹ/g, 'l̥̄'); //ḹ
        x = x.replace(/ḷ/g, 'l̥'); //ḷ

        return x;
    }

    if (transChoice == 'ISO') {
        inp = IASTtoISO(inp);
        //inp = inp.replace(/e/g, "ē");
        //console.log(inp);
    }

    /////////////////////////////////////////////////////////////////////////////////

    //print output
    return inp;
}

//---------------From Uni to Chars---------------------//

//https://stackoverflow.com/questions/17267329/converting-unicode-character-to-string-format
//convert unicode representation to characters
function fromUni(text) {
    return text.replace(/\\u[\dA-F]{4}/gi, function (match) {
        return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
    });
}

//---------------To Uni from Chars---------------------//

//https://stackoverflow.com/questions/5786483/char-to-hex-in-javascript
function toUni(input) {
    var i, l;

    function pad_four(input) {
        var l = input.length;
        if (l === 0) {
            return '0000';
        }
        if (l === 1) {
            return '000' + input;
        }
        if (l === 2) {
            return '00' + input;
        }
        if (l === 3) {
            return '0' + input;
        }
        return input;
    }
    var output = '';
    for (i = 0, l = input.length; i < l; i++) {
        output += '\\u' + pad_four(input.charCodeAt(i).toString(16));
    }
    return output;
}
